import React from 'react'
import { StgOurServicesProps } from './type'
import { Column, Container, Row } from '@/components/base/gridview'
import { functions, path, useApp } from '@wap-client/core'
import Image from '@/components/base/image'
import Dots from '../dots/Dots'

const OurServices: React.FC<StgOurServicesProps> = ({
  background,
  items,
  pagetitle,
  ishome,
}) => {
  const app = useApp()

  return (
    <div
      style={{
        background: `url(${path.asset(
          app.environment,
          background?.src
        )}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      <Container size="extended" className="stg-ourservices-container">
        <Row
          className="stg-ourservices-row"
          xl={{ align: 'center', justify: 'between' }}
        >
          <Column className="stg-ourservices-column">
            <div className="stg-ourservices">
              <h3
                className={functions.classnames(
                  'stg-ourservices-page-title',
                  ishome && 'ourservices-home-title'
                )}
              >
                {pagetitle}
              </h3>
              <div className="stg-ourservices-card">
                {items?.map((item, index) => (
                  <div className="stg-ourservices-card-item" key={index}>
                    <div className="stg-ourservices-card-item-image">
                      <Image {...item.image} alt="" />
                    </div>
                    <div className="stg-ourservices-card-item-texts">
                      <div className="stg-ourservices-card-item-texts-title">
                        {item.title}
                      </div>

                      {item.desc && (
                        <div
                          className="stg-ourservices-card-item-texts-desc"
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default OurServices
